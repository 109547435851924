.phone-number{
    width: 219px;
    background: #FF302F;
    color: #fff;
    text-align: center;
    font-size: 30px;
    border-radius: 15px;
    margin: 0 auto;
    padding: 5px 0;
    font-weight: 700;
}
.phone-number img{
    width: 27px !important;
    margin-right: 6px;
    margin-top: -4px;
}
.phone-sticky {
    position: fixed;
    bottom: 9%;
    left: 45%;
    z-index: 1;
}
.phone-link{
    text-decoration: none;
    color: #fff;
}

@media only screen and (max-width: 767px) {
    .menu-expand .phone-sticky{
        position: relative;
        display: none;
    }
    .phone-sticky {
        left: 25%;
    }
    .phone-number{
        width: 193px;
        font-size: 21px;
        font-weight: 600;
        height: 47px;
    }
}