.footer-component{
    color: #1D1D1F;
    font-size: 14px;
}
.footer-component-links{
    background-color: #F6F8F7;
    padding: 112px 112px;
    display: flex;
    justify-content: space-between;
}
.footer-copy-rights{
    background-color: #051951;
    text-align: center;
    padding: 36px 0;
}
.footer-comp-info-text{
    /* color: #fff; */
    font-size: 14px;
    line-height: 27px;
    margin-top: 20px;
}
.footer-comp-info-text span{
    display: block;
}
.footer-header{
    font-weight: bold;
    font-size: 22px;
}
.quick-link{
    margin: 0 0 5px 0;
}
/* .quick-link, .quick-link-img{
    display: flex;
}
.quick-link{
    margin: 0 0 12px;
    padding: 0;
    font-weight: 700
} */
.quick-link-img img{
    max-width: 100%;
}
.footer-comp-info{
    max-width: 262px;
}
.footer-logo{
    width: 158px;
}
.social-media-icons {
    background: #0152A8;
    margin-right: 10px;
    color: #fff;
    border-radius: 10px;
}
.mr-10{
    margin-right: 10px;
}
@media only screen and (max-width: 767px) {
    .footer-component-links{
        padding: 15px;
        display: block;
     }
     .footer-comp-info{
        max-width: 100%; 
        text-align: center;   
     }
     .mobile-footer{
        width: 50%;
        float: left;
     }
     .footer-section{
        /* text-align: center; */
     }
     .footer-header{
        margin-bottom: 5px;
        margin-top: 20px;
     }
     .footer-comp-info-text{
        margin-top: 5px;
     }
}