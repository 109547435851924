.page-service-section{
    background-color: #fff;
    padding-bottom: 40px;
}
.page-service-heading-section{

    width: 919px;
    text-align: center;
    margin: 0 auto;
    padding-top: 40px;
}
.page-service-title{
    font-size: 36px;
    line-height: 1.3;
    margin: 0 auto 20px;
    width: 65%;
}
.page-service-des{
    font-size: 18px;
    color: #2E4049;
}
.page-service-offered-section{
    display: flex;
    justify-content: center;
    margin-top: 50px;
}
.page-service-image-des{
    font-size: 18px;
    line-height: 32px;
    margin-bottom: 0;
    margin-top: 26px;
}
.page-service-offered-image{
    margin-left: 30px;
    width: 600px;
}
.explore-service-section{
    background: #F5F6F7;
    padding: 0 115px;
}
.explore-service-card-section{
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
    margin-top: 40px;
}
.text-left{
    text-align: left !important;
}
.margin-0{
    margin: 0 !important;
}

@media only screen and (max-width: 767px) {
    .service-title{
        font-size: 31px;
        font-weight: bold;
        text-align: center;
    }
    .service-tab-content{
        margin-top: 25px;
    }
    .page-service-title{
        font-size: 28px;
        line-height: 1.3;
        margin: 0 auto 20px;
        width: 100%;
    }
    .nav-item{
        width: 100%;
        display: block;
    }
    .border-right{
        border: 0;
    }
    .page-service-heading-section{
        width: 100%;
        padding-left: 25px;
        padding-right: 25px;
    }
    .page-service-offered-section{
        margin-top: 40px;
        flex-wrap: wrap;
        padding: 0 25px;
    }
    .page-service-offered-image{
        width: 100%;
        margin-left: 0;
        margin-top: 30px;
    }
    .page-service-offered-image img{
        max-width: 100%;
    }
    .explore-service-section{
        padding: 0;
    }
    .explore-service-card-section{
        padding: 0 25px;
    }
    .explore-service-card-content{
        width: 100%;
    }
    .explore-service-card-content img{
        max-width: 100%;
    }
    .services-offered-content-row{
        display: block;
    }
    .services-cell{
        display: inline-block;
    width: 50%;
    margin-bottom: 20px;
    text-align: center;
    }
    .services-cell img{
    width: 100%;
    }
    .services-offered-content{
        display: block;
        margin-top: 50px;
    }
}