.app-container {
  background-color: #f5f6f7;
  margin: 0 auto;
}
.app-page-container {
  position: relative;
  padding-top: 81px;
}
.app-layout{
  /* max-width: 1440px; */
  margin: 0 auto;
}
@media only screen and (max-width: 767px) {
  .app-page-container{
    padding-top: 74px;
  }
}

