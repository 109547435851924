.blog-section{
    margin-top: 50px;
}
.blog-section-desc{
    font-size: 18px;
    text-align: center;
    font-weight: 400;
    width: 50%;
    margin: 0 auto;
}
.blog-card-section{
    display: flex;
    justify-content: space-between;
    margin-top: 25px;
    flex-flow: wrap;
}
.blog-section-title{
    font-size: 36px;
    font-weight: 700;
    text-align: center;
}
.blog-card {
    width: 392px;
    background: #fff;
    margin-bottom: 50px;
}
.blog-description-section{
    padding: 20px 0;
}
.blog-like-comment{
    display: flex;
    font-size: 15px;
    font-weight: 500;
    justify-content: space-between;
    border-bottom: 1px solid #DCDCDC;
    padding-bottom: 20px;
}
.blog-like{
    margin-right: 30px;
}
.blog-link, .blog-link a{
    color: #272DB0;
    font-weight: bold;
    text-underline-offset: 8px;
}
.blog-description{
    margin-top: 15px;
    font-weight: 600;
    font-size: 18px;
}
.blog-writer{
    display: flex;
}
.blog-writer p {
    margin: 0;
}
.blog-user{
    margin-left: 15px;
}
.blog-user-name{
    font-size: 15px;
    color: #061C3D;
    font-weight: bold;
}
.blog-read-time{
    font-size: 13px;
    color: #42526B;
}