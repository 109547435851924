.header-container {
  position: fixed;
    z-index: 1;
    background: #fff;
    /* max-width: 1440px; */
    width: 100%;
    display: flex;
    align-items: baseline;
    justify-content: space-between;
}
.mobile-menu{
  display: none;
}
.header-logo {
  display: inline-block;
  margin-left: 105px;
}
.header-logo img {
  width: 206px;
}
.header-menus {
  float: right;
  padding-top: 2.3%;
  margin-right: 150px
}
.header-menus li {
  display: inline-block;
  list-style: none;
  margin-right: 41px;
  margin-top: 16px;
  height: 55px;
  width: auto;
  text-align: center;
  
}
.header-menus li:last-child {
  margin-right: 0;
}
.header-menus li a, .header-menus li p {
  text-decoration: none;
  color: #394149;
  font-weight: bold;
}
.header-menus li.active-menu {
  border-bottom: 3px solid #272db0;
}
.header-menus li.active-menu a, .header-menus li.active-menu p {
  color: #272db0;
}
.close-menu{
  display: none;
}
.header-nav li p{
  cursor: pointer;
}
.subMenu {
  position: absolute;
  background: #fff;
  width: 200px;
  text-align: left;
  color: #394149;
  top: 110px;
  top: 110px;
  padding: 30px 30px 10px 30px;
  border: 0;
}
.serviceSubMenu{
  width: 300px;
}
.header-menus li.active-menu a.subMenuLink{
  color: #394149 !important;
  display: block;
  padding-bottom: 20px;
}
@media only screen and (max-width: 767px) {
  .mobile-menu{
    display: inline-block;
    width: 29px;
    height: 38px;
  }
  .ham-menu-bar{
    width: 100%;
    height: 3px;
    background-color: #000;
    margin-bottom: 10px;
  }
  .mobile-menu, .header-logo{
    margin : 15px 0 0 15px;
  }
  .header-container {
    padding: 0;
    display: block;
  }
  .header-menus{
    position: absolute;
    top: 0;
    width: 100%;
    height: calc(100vh);
    background-color: #fff;
    display: none;
  }
  .close-menu{
    display: block;
  }
  .header-logo {
    vertical-align: text-bottom;
    margin-left: 25px;
  }
  .header-logo img {
    height: 58px;
  }
  .header-menus.show-menu{
    display: block;
    z-index: 1;
  }
  .close-menu{
    position: absolute;
    top: 15px;
    right: 12px;
    width: 30px;
    height: 30px;
  }
  .close-menu img{
    width: 100%;
  }
  .header-nav{
    height: 100%;
    padding: 30px;
  }
  .header-menus li{
    display: block;
    font-size: 18px;
    margin-bottom: 41px;
    text-align: left;
    height: auto;
  }
  .subMenu{
    font-size: 16px;
    position: relative;
    padding-top: 10px;
    top: 0;
    padding-bottom: 0;
  }
  .header-menus li.active-menu{
    border: 0;
  }
  .header-menus li.active-menu a.subMenuLink:last-child{
    padding-bottom: 0;
  }
}
