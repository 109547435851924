.partners-title{
    font-size: 26px;
    text-align: center;
    padding-top: 100px;
}

.partners-image-section{
    margin-top: 30px;
    display: flex;
    justify-content: space-between;
}
.react-multiple-carousel__arrow, .react-multi-carousel-dot-list{
    display: none;
}
@media only screen and (max-width: 767px) {
    .partners-title{
        padding-top: 40px;
    }
    .partners-image-section{
        flex-wrap: wrap;
    }
    .partners-image-section img{
        margin-bottom: 20px;
    }
}