.carousel-caption {
  text-align: left;
  width: 51%;
}
.carousel-caption{
  position: absolute;
    left: 8%;
    color: #fff;
    margin: auto 0;
    bottom: 0;
    right: 0;
    top: 39%;
    -ms-transform: translateY(-50%);
    transform: translateY(-50%);
}
.caption-title{
  font-size: 23px;
  text-decoration: underline;
  text-underline-offset: 10px;
}
.bold-text{
  font-weight: 700;
}
.carousel-caption h3 {
  font-size: 47px;
  padding-right: 4%;
  line-height: 60px;
  font-weight: 700;
}
@media only screen and (max-width: 767px) {
  .carousel-caption h3 {
    font-size: 18px !important;
  }
  .carousel-caption {
    top: 8px !important
  }
}

.carousel-caption p {
  font-size: 18px;
  font-weight: 500;
}

.carousel-indicators{
  justify-content: flex-start;
  margin-left: 8%;
  bottom: 1%;
}
.carousel-indicators [data-bs-target]{
  height: 15px;
  width: 15px;
  border-radius: 50%;
}
.custom-carousel{
  height: 390px;
  overflow: hidden;
}
.carousel{
  height: 390px;
}
.carousel img{
  margin-top: -100px;
}
@media only screen and (max-width: 767px) {
  .custom-carousel{
    overflow: hidden;
    width: 100%;
    height: auto;
  }
  .menu-expand .carousel-indicators{
    display: none;
  }
  .carousel img{
    margin-top: 0;
  }
  .carousel, .carousel-inner, .carousel-item, .carousel-item img{
    height: 100%;
  }
  .carousel-indicators{
    bottom: 0;
  }
  .carousel-caption {
    text-align: left;
    width: auto;
    top: 0;
    transform: none;
    left: 0;
  }
  .carousel-caption h3 {
    font-size: 25px;
    font-weight: 700;
    padding-right: 4%;
    line-height: 30px;
    margin-left: 50px;
  }
  
  .carousel-caption p {
    font-size: 13px;
    font-weight: 500;
    line-height: 20px;
    margin-left: 50px;

  }
  .carousel-indicators{
    justify-content: center
  }
}
