.services-offered-content{
    margin-top: 50px;
    max-width: 1032px;
    margin: 0 auto;
    display: table;
    width: 100%;
    margin-top: 115px;
    padding-bottom: 50px;
}
.services-offered-content-row{
    display: flex;
    justify-content: space-between;
}
.border-right{
    border-right: 1px solid #969696;
    
}
.services-cell{
    display: flex;
    flex-direction: column;
    align-items: center;
    padding-right: 7%;
}