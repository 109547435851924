.contact-page-caption{
    width: 998px;
    text-align: center;
    margin: 0 auto;
    position: absolute;
    top: 155px;
    left: 50%;
    transform: translate(-50%, -50%);
}
.contact-page-caption h2{
    font-size: 41px;
    color: #fff;
    font-weight: bold;
}
.contact-page-caption p{
    font-size: 18px;
    color: #fff;
}

.contact-form{
    display: flex;
    position: absolute;
    top: 53%;
    margin: 0 82px;
}
.contact-get-in-touch{
    width: 560px;
    background: #0152A8;
    padding: 50px;
    color: #fff;
}
.contact-get-header{
    font-size: 36px;
    font-weight: bold;
}

.contact-icon-img{
    width: 64px;
}
.contact-email-icon-section{
    display: flex;
    margin-bottom: 20px;
    align-items: center;
}
.contact-email-icon-section p{
    margin-bottom: 0;
}
.contact-phone-icon-section{
    display: flex;
    margin-bottom: 20px;
    align-items: center;
}
.contact-phone-icon-section p{
    margin-bottom: 0;
}
.contact-address-icon-section{
    display: flex;
    align-items: center;
}
.contact-address-icon-section p{
    margin-bottom: 0;
}
.contact-icon-img img{
    width: 64px;
}
.contact-get-content{
    margin-left: 20px;
}
.social-media-section{
    margin-top: 100px;
}
.social-media-images{
    display: flex;
}
.page-banner .social-media-icons{
    width: 44px;
    margin-right: 10px;
}
.contact-email-section{
    background: #fff;
    width: 716px;
    padding: 50px;
}
.contact-label{
    font-size: 16px;
    color: #000;
    width: 100%;
}
.select-ambulance{
    width: 100%;
    height: 48px;
    background: #FEFEFE;
    border: 1px solid #C4C4C4;
    border-radius: 2px;
    font-size: 16px;
    color: #666666;
    padding-left: 20px;
  }
  .contact-phone-field input{
    width: 100%;
  }
  .contact-message-field textarea{
    width: 100%;
  }
.email-submit-button{
    width: 216px;
    height: 48px;
    padding: 0 32px;
    margin-top: 50px;
    color: #fff;
    font-weight: bold;
    background: #0152A8;
    border-radius: 5px;
    border: none;
}
.contact-email-name-section{
    display: flex;
}
.contact-input-field{
    width: 298px
}
.contact-input-field input{
    width: 100%
}
.email-heading{
    font-size: 50px;
    font-weight: 600;
    margin-bottom: 0;
}
.email-text{
    font-size: 24px;
    font-weight: 600;
    line-height: 40px;
    margin-top: 30px;
    margin-bottom: 60px;
    padding: 0 20%;
}
.email-name, .email-field{
    width: 48%;
}
.email-name input, .email-field input{
    width: 100%;
}
.email-subject{
    width: 100%;
    margin-top: 30px;
}
.email-message{
    width: 100%;
    margin-top: 30px;
}

.error-msg{
    color: red;
    text-align: left;
    margin-bottom: 0;
}
.page-contact{
    margin-bottom: 409px;
}
@media only screen and (max-width: 767px) {
.page-contact .page-banner .contact-banner-img{
    /* height: 264px; */
}
.page-contact{
    margin-bottom: 0;
}
.contact-form{
    position: relative;
    top: 0;
    margin: 0;
    flex-wrap: wrap;
}
.social-media-section{
    margin-top: 29px;
}
.contact-page-caption{
    width: 100%;
    top: 78px
}
.contact-page-caption h2{
    font-size: 26px;
}
.contact-get-in-touch{
    width: 100%;
    padding: 25px;
}
    .contact-email-section{
        margin-top: 0;
        padding: 25px;
        width: 100%;
    }
    .contact-input-field{
        width: 100%;
        margin-left: 0 !important;
    }
    .contact-email-name-section{
        display: block;
    }
    .email-heading{
        font-size: 25px;
        text-align: left;
    }
    .email-text{
        font-size: 16px;
        line-height: 23px;
        padding: 0;
        text-align: left;

    }
    .conatct-email-section .heading-dash{
        display: none;
    }
    .email-name, .email-field{
        width: 100%;
    }
    .email-field{
        margin-top: 30px;
    }
    .email-submit-button{
        float: none;
    }
}