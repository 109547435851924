.page-caption-whoweare{
    max-width: 500px !important;
}
.whoweare-content-text{
    font-size: 18px;
    line-height: 32px;
    font-weight: 400;
}
.whoweare-content-text p{
    margin-bottom: 0;
}
.whoweare-section {
    padding-top: 100px;
    padding-bottom: 100px;
}
.whoweare-content{
    margin: 0px 17.5%;
}
.whoweare-content-title{
    font-size: 26px;
    font-weight: bold;
}
.whoweare-services{
    background-color: #f4f4f4;
    margin: 30px 0;
    display: flex;
    flex-wrap: wrap;
    justify-content: space-around;
    padding: 0 35px 35px;
}
.whoweare-services-card{
    width: 360px;
    display: flex;
    margin-top: 35px;
}
.whoweare-services-card-image{
    margin-right: 20px;
}
.whoweare-services-content-title{
    font-size: 26px;
    font-weight: bold;
    margin-bottom: 0;
}
.whoweare-services-content-description{
    font-size: 18px;
    line-height: 21px;
    margin-top: 15px;
    margin-bottom: 0;
}

@media only screen and (max-width: 767px) {
    .page-whowe-are .page-banner img{
        height: 148px;
    }
    .whoweare-section{
        padding: 25px;
    }
    .whoweare-content{
        margin: 0;
    }
    .blog-section-desc{
        width: 100%;
    }
    .blog-card-section{
        flex-wrap: wrap;
    }
    .blog-card{
        margin-bottom: 25px;
    }
    .blog-card-image{
        max-width: 100%;
    }
}