.accordion-item{
    margin-bottom: 13px;
    min-height: 70px;
    border-top: 1px solid #dee2e6 !important;
    border-radius: 3px !important;
}
.accordion-button{
    height: 95%;
    padding-bottom: 15px;
    font-size: 18px;
    outline: none;
    font-weight: bold;
}
.accordion-header{
    margin-bottom: 0;
    height: 70px;
    color: #000;
    font-weight: bold;
    font-size: 18px;
}
.accordion-button:not(.collapsed){
    background-color: #fff !important;
}
.service-accordion{
    width: 570px;
}