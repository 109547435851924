.service-head{
    margin-top: 100px;
    text-align: center;
}


.service-title{
    font-size: 46px;
    font-weight: bold;
    text-align: center;
}
.services-tab{
    display: table;
    width: 100%;
}
.service-tab-content{
    width: 100%;
    margin-top: 50px;
}
.nav-item{
    display: table-cell;
    
}
.nav-tabs .nav-link{
    margin-bottom: 0;
    border-top-left-radius: 0;
    border-top-right-radius: 0;
    font-size: 16px;
    color: #969696;
    padding: 0;
    display: block;
    width: 100%;
    text-align: center;
    padding-bottom: 15px;
    border-bottom: 2px solid #969696;
    border-top: 0;
    border-left: 0;
    border-right: 0;
}
.nav-link.active{
    border: 0;
    border-bottom: 2px solid #FF302F;
}
.nav-tabs .nav-link.active{
    color: #FF302F;
    font-weight: bold;
    border-color: #FF302F !important;
}
.nav-link:focus, .nav-link:hover{
    color: inherit;
}
.nav-tabs .nav-link:focus, .nav-tabs .nav-link:hover {
    isolation: isolate;
    border-color: #969696;
    border-top: 0;
    border-left: 0;
    border-right: 0;
}
.nav-link:focus-visible {
    outline: 0;
    box-shadow: none;
}
.tab-content .product-content{
    margin-top: 50px;
}
@media only screen and (max-width: 767px) {
    .nav-tabs .nav-link{
        padding-bottom: 6px;
        padding-top: 10px;
    }
}