.product-content{
    display: flex;
    margin-top: 75px;
    color: #000;
}
.product-image-content{
    width: 50%;
}
.product-image{
    width: 100%;
}
.product-description-content{
    width: 50%
}
.product-description-inner-content{
    max-width: 90%;
    margin-top: 35px;
}
.product-space{
    margin-left: 100px;
}
.product-title{
    font-size: 18px;
    font-weight: bold;
    margin-bottom: 20px;
}
.product-title-highlight{
    text-decoration: underline;
    text-underline-offset: 11px;
    text-decoration-color: #FFB900;
}
.product-sub-title{
    font-size: 27px;
    font-weight: bold;
    line-height: 43px;
}
.product-description{
    font-size: 16px;
    line-height: 25px;
    margin-bottom: 35px;
}
.caption-content{
    display: flex;
}
.product-caption-title{
    font-weight: bold;
    font-size: 18px;
    margin-bottom: 5px;
}
.caption-description-content{
    font-size: 16px;
}
.caption-content-check{
    margin-right: 15px;
}
.caption-description{
    margin-top: 6px;
}
@media only screen and (max-width: 767px) {
    .product-content{
        margin-top: 40px;
        display: block;
    }
    .product-image-content{
        width: 100%;
    }
    .product-space{
        margin-left: 0;
    }
    .product-description-content{
        width: 100%;
    }
}