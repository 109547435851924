.descriptionSection{
margin: 45px 0;
}
.section-title{
    text-align: center;
    font-weight: bold;
    margin-bottom: 30px;
    font-size: 27px;
}
.descContent{

}