.corp-page{
    /* background: #fff;
    padding: 0 63px; */
}
.corp-top-left-section img{
    width: 100%;
}
.corp-top-banner{
    display: flex;
    background: #fff;
    padding: 80px 107px;
}
.corp-bottom-banner{
    padding: 80px 107px 0;
}
.corp-top-left-section{
    width: 625px;

}
.corp-top-right-section{
    margin-left: 45px;
}
.corp-users-count{
    background: #272DB0;
    height: 199px;
    width: 226px;
    border-radius: 5px;
    color: #fff;
    text-align: center;
    padding-top: 47px;
    margin-right: 12px;
}
.corp-top-bottom-cont{
    display: flex;
    margin-top: 15px;
    justify-content: space-between;
    color: #fff;
}
.corp-top-user-img{
    width: 288px;
}
.corp-user-number{
    font-size: 37px;
    margin-bottom: 0;
}
.corp-user-desc{
    font-size: 17px;
    margin-bottom: 0;

}
.corp-right-title{
    font-size: 36px;
    font-weight: bold;
    margin-bottom: 5px;
}
.corp-right-desc{
    font-size: 18px;
    margin-bottom: 30px;
}
.corp-top-card{
    display: flex;
    margin-bottom: 17px;
}
.corp-top-card-img{
    width: 96px;
    height: 98px;
}
.corp-top-card-desc{
    margin-left: 10px;
}
.corp-top-card-title{
    font-size: 26px;
    font-weight: bold;
    margin: 0;
}
.corp-top-card-des{
    font-size: 18px;
    color: #2E4049;
    margin: 0;
}
.corp-card-section{
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
}
.corp-bottom-title{
    font-size: 36px;
    text-align: center;
    font-weight: bold;
}
.corp-card{
    width: 392px;
    background: #fff;
    padding: 25px;
    margin-bottom: 25px;
    display: flex;
    flex-direction: column;
    justify-content: space-around;
}
.corp-card-badge{
    display: inline-block;
    background: #F6F6F6;
    border-radius: 11px;
    font-size: 18px;
    color: #2F2F2F;
    font-weight: bold;
    padding: 10px 20px;
    margin-bottom: 10px;
}
.corp-card-title{
    font-size: 26px;
    font-weight: bold;
    color: #2F2F2F;
    margin-bottom: 5px;
}
.corp-card-des{
    font-size: 18px;
    color: #2E4049;
}
.corp-card-events{
    display: flex;
    border-top: 1px solid #DCDCDC;
    padding: 20px 0 0;
    justify-content: space-between;
}

@media only screen and (max-width: 767px) {
    .corp-top-banner{
        padding: 25px;
        flex-wrap: wrap;
    }
    .corp-top-left-section{
        width: 100%;
    }
    .corp-top-right-section{

    margin-left: 0;
    margin-top: 40px;
    }
    .corp-users-count{
        height: 126px;
        padding-top: 36px;
    }
    .corp-user-number{
        font-size: 20px;
    }
    .corp-user-desc{
        font-size: 14px;
    }
    .corp-bottom-banner{
        padding: 25px;
    }
    .corp-bottom-title{
        text-align: left;
    }
}
.secondary {
  width: 188px;
  padding: 16px 32px;
  height: 59px;
  text-transform: capitalize;
  font-weight: bold;
  border: 0;
  border-radius: 11px;
  background-color: #fff;
  color: #000;
  border: 1px solid #DCDCDC;
  border-radius: 11px;
  font-size: 18px;
  padding: 10px 30px;
  width: auto;
  height: auto;
  font-weight: normal;
}
