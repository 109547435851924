.explore-service-card-content{
    width: 390px;
    margin-bottom: 30px;
    background: #fff;
    border-radius: 11px;
}
.explore-service-card-description{
    border: 1px solid #969696;
    border-top: 0;
    border-bottom-left-radius: 11px;
    border-bottom-right-radius: 11px;
    text-align: center;
    padding: 20px 33px;
    height: 200px;
}
.explore-card-tile{
    font-size: 18px;
    font-weight: bold;
    
}
.explore-description{
    font-size: 14px;
}
.explore-link{
    text-decoration: none;
}